import React, { Component } from "react";

class FullServices extends Component {
  render() {
    return (
      <div class="section sec-services">
        <div class="container">
          <div class="row mb-5">
            <div class="col-lg-5 mx-auto text-center" data-aos="fade-up">
              <h2 class="heading" style={{ color: "#BF953F" }}>
                Featured Services
              </h2>
              <p>
                Lorem ipsum dolor sit amet. Ut impedit maiores qui cumque
                galisum qui quod reprehenderit qui repudiandae enim et veniam
                laborum sit maxime quisquam est sunt illo
              </p>
            </div>
          </div>

          <div class="row">
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-4 mb-5"
              data-aos="fade-up"
            >
              <div class="service text-center">
                <div>
                  <span class="bi-cash-coin"></span>
                  <h3>Faster payments</h3>
                  <p class="mb-4">
                    Et accusamus quia id consequuntur pariatur est minima
                    impedit et voluptatem assumenda. Quo perferendis asperiores
                    qui omnis impedit ea blanditiis dolores et maiores velit.
                  </p>
                  <p>
                    <a href="#" class="btn btn-outline-dark py-2 px-3">
                      Read more
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-4 mb-5"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="service text-center">
                <div>
                  <span class="bi-chat-text"></span>
                  <h3>Grow your business</h3>
                  <p class="mb-4">
                    Et accusamus quia id consequuntur pariatur est minima
                    impedit et voluptatem assumenda. Quo perferendis asperiores
                    qui omnis impedit ea blanditiis dolores et maiores velit.
                  </p>
                  <p>
                    <a href="#" class="btn btn-outline-dark py-2 px-3">
                      Read more
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-4 mb-5"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="service text-center">
                <div>
                  <span class="bi-fingerprint"></span>
                  <h3>Investments</h3>
                  <p class="mb-4">
                    Et accusamus quia id consequuntur pariatur est minima
                    impedit et voluptatem assumenda. Quo perferendis asperiores
                    qui omnis impedit ea blanditiis dolores et maiores velit.
                  </p>
                  <p>
                    <a href="#" class="btn btn-outline-dark py-2 px-3">
                      Read more
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div
              class="col-12 col-sm-6 col-md-6 col-lg-4 mb-5"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="service text-center">
                <div>
                  <span class="bi-gear"></span>
                  <h3>Payment & Cards</h3>
                  <p class="mb-4">
                    Et accusamus quia id consequuntur pariatur est minima
                    impedit et voluptatem assumenda. Quo perferendis asperiores
                    qui omnis impedit ea blanditiis dolores et maiores velit.
                  </p>
                  <p>
                    <a href="#" class="btn btn-outline-dark py-2 px-3">
                      Read more
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-4 mb-5"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="service text-center">
                <div>
                  <span class="bi-graph-up-arrow"></span>
                  <h3>Strategic Finance</h3>
                  <p class="mb-4">
                    Et accusamus quia id consequuntur pariatur est minima
                    impedit et voluptatem assumenda. Quo perferendis asperiores
                    qui omnis impedit ea blanditiis dolores et maiores velit.
                  </p>
                  <p>
                    <a href="#" class="btn btn-outline-dark py-2 px-3">
                      Read more
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-4 mb-5"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div class="service text-center">
                <div>
                  <span class="bi-layers"></span>
                  <h3>Digital Currency</h3>
                  <p class="mb-4">
                    Et accusamus quia id consequuntur pariatur est minima
                    impedit et voluptatem assumenda. Quo perferendis asperiores
                    qui omnis impedit ea blanditiis dolores et maiores velit.
                  </p>
                  <p>
                    <a href="#" class="btn btn-outline-dark py-2 px-3">
                      Read more
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FullServices;
