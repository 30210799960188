import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div class="site-footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="widget">
                <h3>About</h3>
                <p>
                  Asher Financial Solutions provide easy and simple online
                  accounting services and business information to help business
                  owners make a profit. We remove the stress and frustration
                  from the daily business operations and make finance easy.
                </p>
              </div>
              <div class="widget">
                <address>
                  5 St George's Mall <br />
                  Cape Town <br />
                  South Africa <br />
                  8000
                </address>
                <ul class="list-unstyled links">
                  <li>
                    <a href="tel:0623539480">062-353-9480</a>
                  </li>
                  <li>
                    <a href="mailto:info@afs.co.za">
                      <span class="__cf_email__">info@afs.co.za</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="widget">
                <h3>Navigation</h3>
                <ul class="list-unstyled links mb-4">
                  <li>
                    <a href="/services">Our Services</a>
                  </li>
                  <li>
                    <a href="/about">About us</a>
                  </li>
                  <li>
                    <a href="/contact">Contact us</a>
                  </li>
                </ul>

                <h3>Social</h3>
                <ul class="list-unstyled social">
                  <li>
                    <a href="#">
                      <span class="fa fa-facebook"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="fa fa-twitter"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="fa fa-instagram"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="fa fa-linkedin"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="fa fa-pinterest"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="fa fa-dribbble"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
