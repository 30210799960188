import React, { Component } from "react";

class AboutColumnedSection extends Component {
  render() {
    return (
      <div class="section sec-halfs py-0 my-4">
        <div class="half-content d-lg-flex align-items-stretch">
          <div
            class="img order-0"
            data-aos="fade-in"
            data-aos-delay="100"
          ></div>
          <div class="text">
            <h2 class="heading mb-3">AFS Visions & Mission</h2>
            <p class="mb-5">
              We focus on helping small business owners become profitable and
              provide them with our services at a reduced cost. To meet our
              mission, we offer an easy and affordable 3-fold online website to
              small business owners. Our aim is to allow business owners to save
              money and help them be compliant and make valuable management
              decisions from their numbers.
            </p>
          </div>
        </div>

        <div class="half-content d-lg-flex align-items-stretch">
          <div class="img order-2" data-aos="fade-in"></div>
          <div class="text">
            <h2 class="heading mb-3">Why Us?</h2>
            <ul class="mb-5">
              <li>No monthly subscriptions fees</li>
              <li>Free income and expense processing daily</li>
              <li>Free cash flow status emails</li>
              <li>Free business update emails</li>
              <li>Only pay for requested financial documents</li>
              <li>Pay once off sign-up fee</li>
              <li>Easy and simple online accounting system </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutColumnedSection;
