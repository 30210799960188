import React, { Component } from "react";

class ContactBanner extends Component {
  render() {
    return (
      <div class="section sec-cta overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-lg-5" data-aos="fade-up" data-aos-delay="0">
              <h2 class="heading">Wanna Talk To Us?</h2>
              <p>
                We can help! Our team of experts are always on hand to answer any questions.
              </p>
            </div>
            <div
              class="col-lg-5 text-end"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <a href="/contact" class="btn btn-outline-white">
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactBanner;
