import React, { Component } from "react";
import ImageGrid from "../components/ImageGrid";
import Resources from '../components/ResourceColumnedSection';

class LegalConsult extends Component {
  render() {
    return (
      <div>
        <div class="hero overlay inner-page">
          <div class="container">
            <div class="row align-items-center justify-content-center text-center pt-5">
              <div class="col-lg-6">
                <h1 class="heading mb-3 text-uppercase" data-aos="fade-up">
                  Resources <br /> & <br /> Legal Consult
                </h1>
              </div>
            </div>
          </div>
        </div>

        <Resources />
        <ImageGrid />
      </div>
    );
  }
}

export default LegalConsult;
