import React, { Component } from "react";
import Img from "../images/img-1.jpg";

class Hero extends Component {
  render() {
    return (
      <div class="hero overlay">
        <div class="container">
          <div class="row align-items-center justify-content-between pt-5 mt-5">
            <div class="col-lg-6 text-center text-lg-start pe-lg-5">
              <h1 class="heading mb-3 text-uppercase" data-aos="fade-up">
                Smart software for financial freedom.
              </h1>
              <p
                class="text-white mb-5"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Asher Financial Solutions provide easy and simple online accounting services and
                business information to help business owners make a profit. We
                remove the stress and frustration from the daily business
                operations and make finance easy.
              </p>
              <div
                class="align-items-center mb-5 mm"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <a href="/contact" class="btn btn-outline-light me-4">
                  Contact us
                </a>
              </div>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="300">
              <div class="img-wrap">
                <img src={Img} alt="Image" class="img-fluid rounded" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hero;
