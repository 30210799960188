import React, { Component } from "react";
import ThreeColumedBanner from "../components/ThreeColumnedBanner";
import AboutColumnedSection from "../components/AboutColumnedSections";
import OurTeam from '../components/OurTeam';

class About extends Component {
  render() {
    return (
      <div>
        <div class="hero overlay inner-page">
          <div class="container">
            <div class="row align-items-center justify-content-center text-center pt-5">
              <div class="col-lg-6">
                <h1 class="heading mb-3 text-uppercase" data-aos="fade-up">
                  About Us
                </h1>
              </div>
            </div>
          </div>
        </div>
        <AboutColumnedSection />
        <ThreeColumedBanner />
        <OurTeam />
      </div>
    );
  }
}

export default About;
