import React, { Component } from 'react';
import Hero from '../components/HomeHero';
import TwoColumned from '../components/TwoColumned';
import ThreeColumedBanner from '../components/ThreeColumnedBanner';
import MiniServices from '../components/MiniServices';
import ContactBanner from '../components/ContactBanner';
import LatestNews from '../components/MiniLatestNews';

class Home extends Component {
  render() {
    return (
      <div>
        <Hero />
        <TwoColumned />
        <ThreeColumedBanner />
        <MiniServices />
        <ContactBanner />
        <LatestNews />
      </div>
    )
  }
}

export default Home;