import React, { Component } from "react";
import FullServices from "../components/FullServices";
import ContactBanner from "../components/ContactBanner";
import LatestNews from "../components/MiniLatestNews";

class Services extends Component {
  render() {
    return (
      <div>
        <div class="hero overlay inner-page">
          <div class="container">
            <div class="row align-items-center justify-content-center text-center pt-5">
              <div class="col-lg-6">
                <h1
                  class="heading text-white mb-3 text-uppercase"
                  data-aos="fade-up"
                >
                  Our Services
                </h1>
              </div>
            </div>
          </div>
        </div>

        <FullServices />
        <ContactBanner />
        <LatestNews />
      </div>
    );
  }
}

export default Services;
