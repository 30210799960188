import React, { Component } from "react";

class Resources extends Component {
  render() {
    return (
      <div class="section sec-services">
        <div class="container">
          <div class="row mb-5">
            <div class="col-lg-5 mx-auto text-center" data-aos="fade-up">
              <h2 class="heading text-uppercase" style={{ color: "#BF953F" }}>
                Our Resources
              </h2>
              <p>
              Resources for accountants to help manage their business and clients.
              </p>
            </div>
          </div>

          <div class="row">
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-4 mb-5"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="service text-center">
                <div>
                  <span class="bi-graph-up-arrow"></span>
                  <h3 class="fw-bold">SARS</h3>
                  <p class="mb-4">
                    The South African Revenue Service (SARS) is the country’s
                    tax collection agency. It was established as an autonomous
                    agency under the South African Revenue Service Act 34 of
                    1997 and is responsible for administering South Africa's tax
                    system and customs service.
                  </p>
                </div>
                <div class="text-center">
                  <a
                    href="https://www.sars.gov.za/"
                    class="btn btn-outline-dark py-2 px-3"
                  >
                    Read more
                  </a>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-4 mb-5"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="service text-center">
                <div>
                  <span class="bi-chat-text"></span>
                  <h3 class="fw-bold">Govchain</h3>
                  <p class="mb-4">
                    Starting a new business is one of the most exciting events
                    in the life of any entrepreneur. Govchain provides an
                    indispensable online service for South Africans to register
                    their business quickly and easily within minutes.
                  </p>
                </div>
                <div class="text-center">
                  <a
                    href="https://www.govchain.co.za/"
                    class="btn btn-outline-dark py-2 px-3"
                  >
                    Read more
                  </a>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-4 mb-5"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="service text-center">
                <div>
                  <span class="bi-fingerprint"></span>
                  <h3 class="fw-bold">CIPC</h3>
                  <p class="mb-4">
                    The CIPC is the South African government agency responsible
                    for the registration and maintenance of companies and
                    intellectual property rights. It plays a critical role in
                    the business landscape, enforcing compliance with relevant
                    legislation and ensuring transparency and accountability in
                    business operations.
                  </p>
                </div>
                <div class="text-center">
                  <a
                    href="https://www.cipc.co.za/"
                    class="btn btn-outline-dark py-2 px-3"
                  >
                    Read more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Resources;
