import { useState } from "react";
import AFSLogo from "../images/afs-logo.jpg";

const NavigationMenu = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  return (
    <div>
      <div class="site-mobile-menu site-navbar-target">
        <div class="site-mobile-menu-header" onClick={handleShowNavbar}>
          <div class="site-mobile-menu-close">
            <span class="icofont-close js-menu-toggle"></span>
          </div>
        </div>
        <div class="site-mobile-menu-body"></div>
      </div>

      <nav class="site-nav mb-5">
        <div class="container">
          <div class="menu-bg-wrap">
            <div class="site-navigation">
              <div class="row g-0 align-items-center">
                <div class="col-2">
                   <a href="/" class="logo m-0 float-start">
                    <img src={AFSLogo} />
                  </a>
                </div>
                <div class="col-8 text-center">
                {/* <ul class="js-clone-nav d-none d-lg-inline-block text-start site-menu mx-auto"> */}
                <ul class={`js-clone-nav d-none d-lg-inline-block text-start site-menu mx-auto ${showNavbar && 'active'}`}>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/about">About</a>
                    </li>
                    <li>
                      <a href="/services">Services</a>
                    </li>
                    <li>
                      <a href="/legal-consult">Legal Consult</a>
                    </li>
                    <li>
                      <a href="/contact">Contact Us</a>
                    </li>
                  </ul>
                </div>
                <div class="col-2 text-end" onClick={handleShowNavbar}>
                  <a
                    href="#"
                    class="burger ms-auto float-end site-menu-toggle js-menu-toggle d-inline-block d-lg-none light"
                  >
                    <span></span>
                  </a>

                  <a href="tel:0623539480" class="call-us d-none d-lg-flex align-items-center text-decoration-none">
                    <span class="icon-phone fa fa-phone"></span>
                    <span>062-353-9480</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavigationMenu;
