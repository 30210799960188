import React, { Component } from "react";
// import Person1 from 'https://st4.depositphotos.com/9998432/27431/v/450/depositphotos_274313380-stock-illustration-person-gray-photo-placeholder-woman.jpg';
import Person2 from "../images/person_2.jpg";
import Person3 from "../images/person_3.jpg";

class Team extends Component {
  render() {
    return (
      <div class="section">
        <div class="container">
          <div class="row mb-5">
            <div class="mx-auto text-center" data-aos="fade-up">
              <h2 class="heading text-uppercase" style={{ color: "#BF953F" }}>
                The brains behind <br /> Asher Financial Solutions
              </h2>
              <p>
                Lorem ipsum dolor sit amet. Ut impedit maiores qui cumque
                galisum qui quod reprehenderit qui repudiandae enim et veniam
                laborum sit maxime quisquam est sunt illo
              </p>
            </div>
          </div>

          <div class="row">
            <div
              class="col-lg-4 mb-4 text-center"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <img
                src="https://st4.depositphotos.com/9998432/27431/v/450/depositphotos_274313380-stock-illustration-person-gray-photo-placeholder-woman.jpg"
                alt="Image"
                class="img-fluid w-50 rounded-circle mb-3"
              />
              <h5 class="text-black">James Griffin</h5>
              <p>
                Et accusamus quia id consequuntur pariatur est minima impedit et
                voluptatem assumenda. Quo perferendis asperiores qui omnis
                impedit ea blanditiis dolores et maiores velit.
              </p>
            </div>
            <div
              class="col-lg-4 mb-4 text-center"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <img
                src="https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133352010-stock-illustration-default-placeholder-man-and-woman.jpg"
                alt="Image"
                class="img-fluid w-50 rounded-circle mb-3"
              />
              <h5 class="text-black">Claire Smith</h5>
              <p>
                Et accusamus quia id consequuntur pariatur est minima impedit et
                voluptatem assumenda. Quo perferendis asperiores qui omnis
                impedit ea blanditiis dolores et maiores velit.
              </p>
            </div>
            <div
              class="col-lg-4 mb-4 text-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <img
                src="https://st4.depositphotos.com/9998432/24359/v/600/depositphotos_243599650-stock-illustration-person-gray-photo-placeholder-girl.jpg"
                alt="Image"
                class="img-fluid w-50 rounded-circle mb-3"
              />
              <h5 class="text-black">Jessica Wilson</h5>
              <p>
                Et accusamus quia id consequuntur pariatur est minima impedit et
                voluptatem assumenda. Quo perferendis asperiores qui omnis
                impedit ea blanditiis dolores et maiores velit.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
