import React, { Component } from "react";
import Img1 from "../images/img_v_4.jpg";
import Img2 from "../images/img_h_1.jpg";
import Img4 from "../images/img_h_2.jpg";
import Img5 from "../images/img_h_3.jpg";
import Img6 from "../images/img_h_4.jpg";
import Img7 from "../images/img_h_5.jpg";
import Img9 from "../images/img_v_2.jpg";

class ImageGrid extends Component {
  render() {
    return (
      <div class="site-section">
        <div class="container mt-5">
          <div class="row my-5">
            <div class="col-lg-5 mx-auto text-center" data-aos="fade-up">
              <h2
                class="heading text-uppercase fw-bold"
                style={{ color: "#BF953F" }}
              >
                Legal Consult
              </h2>
              <p>
                At Asher Financial Solutions, you can increase your visibility
                and generate a steady stream of new client leads by listing your
                business on our legal consult directory.
              </p>
            </div>
          </div>

          <div
            class="filters-content mb-5"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div class="row grid">
              <div class="isotope-card col-sm-4 all mockup">
                <a>
                  <img src={Img1} alt="Image" class="img-container" />
                  <div class="contents">
                    <h3>Business Name</h3>
                    <div class="cat">Business Description</div>
                  </div>
                </a>
              </div>

              <div class="isotope-card col-sm-4 all mockup">
                <a>
                  <img src={Img2} alt="Image" class="img-container" />
                  <div class="contents">
                    <h3>Business Name</h3>
                    <div class="cat">Business Description</div>
                  </div>
                </a>
              </div>
              <div class="isotope-card col-sm-4 all mockup">
                <a>
                  <img src={Img7} alt="Image" class="img-container" />
                  <div class="contents">
                    <h3>Business Name</h3>
                    <div class="cat">Business Description</div>
                  </div>
                </a>
              </div>
              <div class="isotope-card col-sm-4 all typography">
                <a>
                  <img src={Img4} alt="Image" class="img-container" />
                  <div class="contents">
                    <h3>Business Name</h3>
                    <div class="cat">Business Description</div>
                  </div>
                </a>
              </div>
              <div class="isotope-card col-sm-4 all mockup">
                <a>
                  <img src={Img5} alt="Image" class="img-container" />
                  <div class="contents">
                    <h3>Business Name</h3>
                    <div class="cat">Business Description</div>
                  </div>
                </a>
              </div>
              <div class="isotope-card col-sm-4 all packaging">
                <a>
                  <img src={Img6} alt="Image" class="img-container" />
                  <div class="contents">
                    <h3>Business Name</h3>
                    <div class="cat">Business Description</div>
                  </div>
                </a>
              </div>
              <div class="isotope-card col-sm-4 all typography">
                <a>
                  <img src={Img7} alt="Image" class="img-container" />
                  <div class="contents">
                    <h3>Business Name</h3>
                    <div class="cat">Business Description</div>
                  </div>
                </a>
              </div>
              <div class="isotope-card col-sm-4 all photography">
                <a>
                  <img src={Img4} alt="Image" class="img-container" />
                  <div class="contents">
                    <h3>Business Name</h3>
                    <div class="cat">Business Description</div>
                  </div>
                </a>
              </div>
              <div class="isotope-card col-sm-4 all photography">
                <a>
                  <img src={Img9} alt="Image" class="img-container" />
                  <div class="contents">
                    <h3>Business Name</h3>
                    <div class="cat">Business Description</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImageGrid;
