import React, { Component } from 'react';
import ImgOne from '../images/img-1.jpg';
import ImgTwo from '../images/img-2.jpg';
import ImgThree from '../images/img-3.jpg';

class LatestNews extends Component {
  render() {
    return(
<div class="section sec-news">
	<div class="container">
		<div class="row mb-5">
			<div class="col-lg-7">
				<h2 class="heading text-uppercase" style={{color:'#BF953F'}}>Latest News</h2>
			</div>
		</div>

		<div class="row">
			<div class="col-lg-4">
				<div class="card post-entry">
					<a href="single.html"><img src={ImgOne} class="card-img-top" alt="Image" /></a>
					<div class="card-body">
						<div><span class="text-uppercase font-weight-bold date">Jan 20, 2021</span></div>
						<h5 class="card-title text-decoration-none"><a href="single.html" class="text-decoration-none fw-bold" style={{color:'#BF953F'}}>Behind the word mountains</a></h5>
						<p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. </p>
						<p class="mt-5 mb-0"><a href="#" style={{color:'#BF953F'}}>Read more</a></p>
					</div>
				</div>
			</div>

			<div class="col-lg-4">
				<div class="card post-entry">
					<a href="single.html"><img src={ImgTwo} class="card-img-top" alt="Image" /></a>
					<div class="card-body">
						<div><span class="text-uppercase font-weight-bold date">Jan 20, 2021</span></div>
						<h5 class="card-title"><a href="single.html" class="text-decoration-none fw-bold" style={{color:'#BF953F'}}>Behind the word mountains</a></h5>
						<p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. </p>
						<p class="mt-5 mb-0"><a href="#" style={{color:'#BF953F'}}>Read more</a></p>
					</div>
				</div>
			</div>

			<div class="col-lg-4">
				<div class="card post-entry">
					<a href="single.html"><img src={ImgThree} class="card-img-top" alt="Image" /></a>
					<div class="card-body">
						<div><span class="text-uppercase font-weight-bold date">Jan 20, 2021</span></div>
						<h5 class="card-title"><a href="single.html" class="text-decoration-none fw-bold" style={{color:'#BF953F'}}>Behind the word mountains</a></h5>
						<p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. </p>
						<p class="mt-5 mb-0"><a href="single.html" style={{color:'#BF953F'}}>Read more</a></p>
					</div>
				</div>
			</div>


		</div>
	</div>
</div>
    )
  }
}

export default LatestNews;