import React, { Component } from "react";

class ContactForm extends Component {
  render() {
    return (
      <div class="section">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-4 mb-5 mb-lg-0"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="contact-info">
                <div class="address mt-2">
                  <i class="fa fa-map-marker"></i>
                  <h4 class="mb-2">Location:</h4>
                  <p>
                    5 St George's Mall <br />
                    Cape Town <br />
                    South Africa <br />
                    8000
                  </p>
                </div>

                <div class="open-hours mt-4">
                  <i class="fa fa-clock-o"></i>
                  <h4 class="mb-2">Open Hours:</h4>
                  <p>
                    Sunday-Friday:
                    <br />
                    11:00 AM - 2300 PM
                  </p>
                </div>

                <div class="email mt-4">
                  <i class="fa fa-envelope"></i>
                  <h4 class="mb-2">Email:</h4>
                  <p>
                    <a href="mailto:info@afs.co.za">info@afs.co.za</a>
                  </p>
                </div>

                <div class="phone mt-4">
                  <i class="fa fa-phone"></i>
                  <h4 class="mb-2">Call:</h4>
                  <p>
                    <a href="tel:0623539480">062-353-9480</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-8" data-aos="fade-up" data-aos-delay="200">
              <form action="#">
                <div class="row">
                  <div class="col-6 mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Your Name"
                    />
                  </div>
                  <div class="col-6 mb-3">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Your Email"
                    />
                  </div>
                  <div class="col-12 mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Subject"
                    />
                  </div>
                  <div class="col-12 mb-3">
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="7"
                      class="form-control"
                      placeholder="Message"
                    ></textarea>
                  </div>

                  <div class="col-12">
                    <input
                      type="submit"
                      value="Send Message"
                      class="btn btn-outline-dark"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;
