import React, { Component } from "react";

class ThreeColumedBanner extends Component {
  render() {
    return (
      <div class="section sec-features">
        <div class="container">
          <div class="row g-5">
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-4"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="feature d-flex">
                <span class="bi-wallet-fill"></span>
                <div>
                  <h3>End-to-end accounting</h3>
                  <p>
                    Right from negotiating deals, to raising sales orders, to
                    invoicing, Asher Financial Solutions handles the day-to-day
                    accounting tasks so you can focus on your business.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-4"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <div class="feature d-flex">
                <span class="bi-bag-check-fill"></span>
                <div>
                  <h3>Payables</h3>
                  <p>
                    Keep track of your payables and know where your money is
                    going. From vendor invoices to expenses, Asher Financial
                    Solutions makes managing payables simple.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="feature d-flex">
                <span class="bi-pie-chart-fill"></span>
                <div>
                  <h3>Easy Collaboration</h3>
                  <p>
                    As your business grows, you can take advantage of additional
                    services from Asher Financial Solutions to manage and
                    control all aspects of your business from any location.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ThreeColumedBanner;
