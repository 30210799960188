import React, { Component } from "react";

class MiniServices extends Component {
  render() {
    return (
      <div class="section sec-services">
        <div class="container">
          <div class="row mb-5">
            <div class="col-lg-5 mx-auto text-center" data-aos="fade-up">
              <h2 class="heading text-uppercase" style={{ color: "#BF953F" }}>
                Our Services
              </h2>
              <p>
                Lorem ipsum dolor sit amet. Qui eligendi sunt cum laboriosam
                deserunt et omnis nesciunt vel cumque voluptatem et laborum
                corrupti. Aut aspernatur fuga et ipsum quos aut quia architecto
                qui tempore corporis et vero illo. Et sint porro et magni optio
                eum facere voluptatem qui quia harum.
              </p>
            </div>
          </div>

          <div class="row">
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-4 mb-5"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="service text-center">
                <div>
                  <span class="bi-graph-up-arrow"></span>
                  <h3>Strategic Finance</h3>
                  <p class="mb-4">
                   Lorem ipsum dolor sit amet. Qui eligendi sunt cum laboriosam
                    deserunt et omnis nesciunt vel cumque voluptatem et laborum
                    corrupti.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-4 mb-5"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="service text-center">
                <div>
                  <span class="bi-chat-text"></span>
                  <h3>Grow your business</h3>
                  <p class="mb-4">
                   Lorem ipsum dolor sit amet. Qui eligendi sunt cum laboriosam
                    deserunt et omnis nesciunt vel cumque voluptatem et laborum
                    corrupti.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-4 mb-5"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="service text-center">
                <div>
                  <span class="bi-fingerprint"></span>
                  <h3>Investments</h3>
                  <p class="mb-4">
                   Lorem ipsum dolor sit amet. Qui eligendi sunt cum laboriosam
                    deserunt et omnis nesciunt vel cumque voluptatem et laborum
                    corrupti.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <a href="/services" class="btn btn-outline-dark py-2 px-3 mt-5">
            Read more
          </a>
        </div>
      </div>
    );
  }
}

export default MiniServices;
