import Home from './containers/home.js';
import About from './containers/about.js';
import Services from './containers/services.js';
import LegalConsult from './containers/legalconsult.js';
import Contact from './containers/contact.js';
import NavigationMenu from './components/NavigationMenu.js';
import Footer from './components/Footer.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

function App() {
  return (
    <div>
      <BrowserRouter>
        <NavigationMenu />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/legal-consult" element={<LegalConsult />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
