import React, { Component } from "react";
import Img from "../images/business-grid.png";

class TwoColumned extends Component {
  render() {
    return (
      <div class="section">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-lg-7 mb-4 mb-lg-0">
              <img
                src={Img}
                alt="Image"
                class="img-fluid rounded
					"
              />
            </div>
            <div class="col-lg-4 ps-lg-2">
              <div class="mb-5">
                <h2 class="text-black h4">What we all about</h2>
                <p>
                  Asher Financial Solutions is your one-stop platform for
                  managing your accounting tasks and organizing your
                  transactions. In one secure place, you can view your company's
                  invoices, reconcile your bank statements, control your
                  expenses and monitor projects.
                  </p>
                  <p>
                  Keeping up with the market means understanding the
                  ever-changing business trends, taxes and more. We help you
                  stay current, from adjusting your accounting to understanding
                  your income and everything in between.
                </p>
              </div>
              <p>
                <a href="/about" class="btn btn-outline-dark py-2">
                  About Us
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TwoColumned;
