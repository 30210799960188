import React, { Component } from "react";
import ContactForm from "../components/ContactForm";

class Contact extends Component {
  render() {
    return (
      <div>
        <div class="hero overlay inner-page">
          <div class="container">
            <div class="row align-items-center justify-content-center text-center pt-5">
              <div class="col-lg-6">
                <h1 class="heading mb-3 text-uppercase" data-aos="fade-up">
                  Contact Us
                </h1>
              </div>
            </div>
          </div>
        </div>
        
        <ContactForm />
      </div>
    );
  }
}

export default Contact;
